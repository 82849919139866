import { useEffect } from 'react';
import GooglePlayIcon from './img/gp-icon.svg';
import AppStoreIcon from './img/a-icon.svg';
import yapay1_sub from './videos/yapay1_sub.mp4';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Contact from './Contact';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

function Home() {
  useEffect(() => {
    // Senja script
    const script = document.createElement('script');
    script.src = "https://widget.senja.io/widget/3bad90fc-993a-46bc-83f2-0a64f49ca16c/platform.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleTryForFree = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.nosher';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.href = 'https://apps.apple.com/app/id6473447642';
    } else {
      window.location.href = 'https://apps.apple.com/app/id6473447642';
    }
  };

  return (
    <main className="min-h-screen bg-white md:bg-[#FDF8F3] flex flex-col relative px-5 md:px-64 pt-4 md:py-8 font-gilroy">
      {/* Header */}
      <header className="w-full flex justify-between items-center mb-16 md:mb-16">
        <Link to="/">
          <img
            alt="Nosher logo"
            src="/noshericonsquare.png"
            className="h-12 w-12 md:h-16 md:w-auto"
          />
        </Link>
        <nav className="flex items-center gap-4 md:gap-8">
          <Link to="/contact" className="text-[#323462] no-underline text-base md:text-base font-bold">
            Contact
          </Link>
          <button 
            onClick={handleTryForFree}
            className="bg-[#FFB963] px-6 py-3 md:px-6 md:py-3 rounded-full text-base font-bold cursor-pointer"
          >
            Try for Free!
          </button>
        </nav>
      </header>

      {/* Main Content */}
      <div className="flex flex-col items-center text-center md:flex-row md:items-start md:text-left md:justify-between md:gap-20">
        {/* Left Column */}
        <div className="w-full md:max-w-[50%]">

          {/* Desktop Heading */}
          <h1 className="text-5xl md:text-6xl mb-8 leading-tight font-bold">
            <span className="text-[#323462]">FIND </span>
            <br />
            <span className="text-[#FFB963]">HEALTHY FOOD</span>
            <br />
            <span className="text-[#323462]">FOR YOUR NEEDS</span>
          </h1>
          
          <p className="px-4 md:px-0 text-xl md:text-2xl mb-12 leading-relaxed text-[#323462]">
            <span className="text-[#FFB963]">Nosher</span>
            {' '}scans ingredient labels on food packages using AI to help you make informed choices.
          </p>

          {/* App Store Buttons */}
          <div className="flex items-center justify-center md:justify-start flex-col md:flex-row gap-3 md:gap-4 w-full max-w-[280px] md:max-w-none mx-auto md:mx-0">
            <a href="https://play.google.com/store/apps/details?id=com.nosher" 
               className="bg-[#27AE60] p-4 md:p-6 rounded-xl w-2/3 md:w-[200px] h-14 md:h-16 flex items-center gap-3">
              <img
                alt="Google Play icon"
                src={GooglePlayIcon}
                className="w-6 md:w-[30px] h-6 md:h-[30px]"
              />
              <div className="flex flex-col items-start">
                <span className="text-white text-sm leading-tight md:-mb-[3px]">GET IT ON</span>
                <span className="text-white text-base font-bold">Google Play</span>
              </div>
            </a>
            <a href="https://apps.apple.com/app/id6473447642" 
               className="bg-[#27AE60] p-4 md:p-6 rounded-xl w-2/3 md:w-[200px] h-14 md:h-16 flex items-center gap-3">
              <img
                alt="App Store icon"
                src={AppStoreIcon}
                className="w-6 md:w-[30px] h-6 md:h-[30px]"
              />
              <div className="flex flex-col items-start">
                <span className="text-white text-xs leading-tight md:-mb-[3px]">Download on the</span>
                <span className="text-white text-base font-bold">App Store</span>
              </div>
            </a>
          </div>
        </div>

        {/* Video Container */}
        <div className="mt-8 md:mt-0 w-full max-w-[300px] md:max-w-[25%] md:flex-1">
          <div className="w-full aspect-[9/16] rounded-3xl overflow-hidden border-[16px] border-[#FFB963]">
            <video
              autoPlay
              loop
              muted
              playsInline
              className="w-full h-full object-cover"
            >
              <source src={yapay1_sub} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <div className="block">
        <h2 className="text-5xl text-[#323462] text-center mt-20 font-bold">
          Join thousands of health-conscious shoppers!
        </h2>

        {/* Testimonials Section */}
        <div className="mt-10 text-center mb-20">
          <h3 className="text-4xl text-[#323462] mb-10 flex items-center justify-center gap-3">
            See what they say <span className="text-3xl">👇</span>
          </h3>

          <div 
            className="senja-embed" 
            data-id="3bad90fc-993a-46bc-83f2-0a64f49ca16c" 
            data-mode="shadow" 
            data-lazyload="false" 
          />
        </div>
      </div>

      <footer className="block border-t border-[#E5E5E5] mt-auto pt-8">
        <div className="flex flex-col md:flex-row justify-between items-center gap-6 md:gap-0">
          {/* Left side links */}
          <div className="flex gap-6">
            <Link to="/contact" className="text-[#323462] no-underline text-base">
              Contact
            </Link>
            <a 
              href="http://nosherapp.com/terms-of-use"
              className="text-[#323462] no-underline text-base"
            >
              Legal
            </a>
          </div>

          {/* Copyright */}
          <div className="text-[#323462] text-base text-center">
            © 2024 Nosher. All rights reserved.
          </div>

          {/* Social Media Links */}
          <div className="flex items-center gap-4">
            <span className="text-[#323462] text-base">
              Follow us on:
            </span>
            <a 
              href="https://www.tiktok.com/@nosherapp" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-[#323462]"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                <path d="M27.9887 9.6098C26.4937 9.6098 25.1113 9.1089 24.0054 8.26718C22.7356 7.30153 21.8192 5.88146 21.4966 4.24966C21.4147 3.84688 21.3737 3.4286 21.3686 3H17.0934V14.7789L17.0883 21.2286C17.0883 22.9533 15.9722 24.4147 14.431 24.9311C13.9805 25.0809 13.4992 25.1531 12.9975 25.1222C12.3523 25.086 11.7533 24.8898 11.2259 24.5748C10.1098 23.9035 9.35716 22.6848 9.33668 21.2854C9.30596 19.1062 11.0519 17.3247 13.2125 17.3247C13.6375 17.3247 14.0471 17.397 14.431 17.5209V14.3038V13.1522C14.0266 13.0903 13.617 13.0593 13.1971 13.0593C10.8317 13.0593 8.61989 14.0508 7.03782 15.8375C5.84487 17.1852 5.12807 18.91 5.01543 20.7174C4.87207 23.0876 5.73223 25.3442 7.40133 27.007C7.64709 27.2497 7.90309 27.4769 8.17445 27.6886C9.60292 28.7989 11.3539 29.403 13.1971 29.403C13.6119 29.403 14.0266 29.3721 14.431 29.3101C16.1514 29.0519 17.7385 28.2567 18.9929 27.007C20.534 25.4733 21.384 23.4336 21.3942 21.2647L21.3737 11.6289C22.1059 12.2021 22.9097 12.672 23.775 13.0386C25.1164 13.6118 26.5398 13.8958 27.999 13.8958V10.7665V9.60463C28.0041 9.6098 27.9887 9.6098 27.9887 9.6098Z" fill="#284327"/>
              </svg>
            </a>
            <a 
              href="https://instagram.com/nosherappcom" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-[#323462]"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram"><rect width="20" height="20" x="2" y="2" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/></svg>
            </a>
          </div>
        </div>
      </footer>
    </main>
  );
}